import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"overflow-y":"auto","max-height":"40vh","margin-bottom":"0px"} }
const _hoisted_2 = { class: "mb-10" }
const _hoisted_3 = { class: "fv-plugins-message-container" }
const _hoisted_4 = { class: "fv-help-block" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "row mb-6" }
const _hoisted_8 = { class: "col-lg-8" }
const _hoisted_9 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_10 = {
  class: "btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow",
  "data-kt-image-input-action": "change",
  "data-bs-toggle": "tooltip",
  title: "Change avatar"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "mb-10" }
const _hoisted_18 = { class: "fv-plugins-message-container" }
const _hoisted_19 = { class: "fv-help-block" }
const _hoisted_20 = { class: "mb-10" }
const _hoisted_21 = { class: "fv-plugins-message-container" }
const _hoisted_22 = { class: "fv-help-block" }
const _hoisted_23 = { class: "mb-10" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = { class: "col-6" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "row mb-6" }
const _hoisted_31 = { class: "col-lg-8" }
const _hoisted_32 = { class: "text-muted fw-bold" }
const _hoisted_33 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_34 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_35 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_36 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_Form = _resolveComponent("Form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "before-close": _ctx.modalClose,
    modelValue: _ctx.dialogAddQuestion,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dialogAddQuestion) = $event)),
    title: "Tambah / Edit Pertanyaan",
    width: "50%",
    "align-center": "true",
    style: { maxHeight: '60vh' }
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("button", {
          type: "reset",
          class: "btn btn-sm btn-white btn-active-light-primary me-2",
          "data-kt-menu-dismiss": "true",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.cancelModal()))
        }, " Batal "),
        _createElementVNode("button", {
          type: "submit",
          id: "kt_ist_submit",
          ref: "submitButton",
          class: "btn btn-primary",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.saveIst()))
        }, _cache[27] || (_cache[27] = [
          _createElementVNode("span", { class: "indicator-label" }, " Simpan ", -1),
          _createElementVNode("span", { class: "indicator-progress" }, [
            _createTextVNode(" Please wait... "),
            _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
          ], -1)
        ]), 512)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Form, {
          id: "kt_ist_form",
          class: "form",
          novalidate: "novalidate",
          onSubmit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.saveIst())),
          "validation-schema": _ctx.istValidator
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "No. :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fno",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "No. ",
                  modelValue: _ctx.istQuestion.IST_Order,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.istQuestion.IST_Order) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ErrorMessage, { name: "fno" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Pertanyaan :", -1)),
              (_ctx.istgrouptype=='PSO')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", {
                            class: "image-input-wrapper w-125px h-125px",
                            style: _normalizeStyle(`background-image: url(${_ctx.avatarImageUrl})`)
                          }, null, 4),
                          _createElementVNode("label", _hoisted_10, [
                            _cache[15] || (_cache[15] = _createElementVNode("i", { class: "bi bi-pencil-fill fs-7" }, null, -1)),
                            _createElementVNode("input", {
                              type: "file",
                              name: "avatar",
                              accept: ".png, .jpg, .jpeg",
                              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
                            }, null, 32),
                            _cache[16] || (_cache[16] = _createElementVNode("input", {
                              type: "hidden",
                              name: "avatar_remove"
                            }, null, -1))
                          ]),
                          _createElementVNode("span", {
                            class: "btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow",
                            "data-kt-image-input-action": "remove",
                            "data-bs-toggle": "tooltip",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeImage())),
                            title: "Remove avatar"
                          }, _cache[17] || (_cache[17] = [
                            _createElementVNode("i", { class: "bi bi-x fs-2" }, null, -1)
                          ]))
                        ]),
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "form-text" }, "Allowed file types: png, jpg, jpeg.", -1))
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_Field, {
                      type: "text",
                      name: "fquest",
                      class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                      placeholder: "Pertanyaan",
                      modelValue: _ctx.istQuestion.IST_Question,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.istQuestion.IST_Question) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_ErrorMessage, { name: "fquest" })
                      ])
                    ])
                  ]))
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[20] || (_cache[20] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "No Jawaban :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fvidquest",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Id Jawaban",
                  modelValue: _ctx.ist_choice.ID,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ist_choice.ID) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ErrorMessage, { name: "fvidquest" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[21] || (_cache[21] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Deskripsi Jawaban :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fvdescquest",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Deskripsi Jawaban",
                  modelValue: _ctx.ist_choice.Description,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ist_choice.Description) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_ErrorMessage, { name: "fvdescquest" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[22] || (_cache[22] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Score Jawaban :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  name: "fscorequest",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Score Jawaban",
                  modelValue: _ctx.ist_correct_answer.Score,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ist_correct_answer.Score) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_ErrorMessage, { name: "fscorequest" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_el_checkbox, {
                modelValue: _ctx.fcorrectanswer,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fcorrectanswer) = $event)),
                label: "Jawaban Benar",
                size: "small"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-sm",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.addQuestionList(_ctx.istQuestion, _ctx.ist_choice, _ctx.ist_correct_answer, _ctx.fcorrectanswer)))
            }, _cache[23] || (_cache[23] = [
              _createElementVNode("span", { class: "indicator-label" }, " Tambah Soal", -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("button", {
              class: "btn btn-danger btn-sm",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.removeQuestionList(_ctx.istQuestion)))
            }, _cache[24] || (_cache[24] = [
              _createElementVNode("span", { class: "indicator-label" }, " Hapus Soal", -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          (_ctx.istQuestion.IST_Choice.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_28, _cache[25] || (_cache[25] = [
                _createElementVNode("b", null, "List Jawaban", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.istQuestion.IST_Choice, (question, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (_ctx.istgrouptype=='PSO')
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("p", _hoisted_32, _toDisplayString(question.ID) + ".", 1),
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", {
                            class: "image-input-wrapper w-125px h-125px",
                            style: _normalizeStyle(`background-image: url(${question.Description})`)
                          }, null, 4)
                        ])
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(question.ID + '. ' + question.Description), 1))
            ], 64))
          }), 128)),
          (_ctx.istQuestion.IST_Correct_Answer.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_35, _cache[26] || (_cache[26] = [
                _createElementVNode("b", null, "Kunci Jawaban", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.istQuestion.IST_Correct_Answer, (questionCorrect, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString("key : " + questionCorrect.ID + ' / Score : ' + questionCorrect.Score), 1))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["before-close", "modelValue"]))
}