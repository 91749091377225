
import { defineComponent, onBeforeMount, ref, watch } from "vue";
import CreateIstGroupComponent from "@/views/psy/ist/component/CreateIstGroupComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";

export default defineComponent({
  name: "ist-group-component",
  components: {
    CreateIstGroupComponent,
  },
  props: {
    widgetClasses: String,
  },
  setup(props, {emit}) {
    var groupCache = ref([] as Array<any>)
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})

    onBeforeMount(() => {
      getIstGroup()
    });

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true
    }

    function emitGroupId(val){
      emit("clickDraw", val);
    }

    function isNeedRefresh(val) {
      getIstGroup()
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function onDelete(val){
      deleteIstGroup(val.IST_Group_Id)
    }

    async function deleteIstGroup(IST_Group_Id){
      const mydatat = {
        Trigger					: "D",
        Route					: "IST_Groups",
        IST_Group_Id			: IST_Group_Id
      }

      groupCache.value = []
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response=>{
        getIstGroup()
      }, err =>{
        err
      })
    }

    async function getIstGroup(){
      const mydatat = {
        Trigger					: "R",
        Route					: "IST_Groups",
        IST_Group_Id			: ""
      }

      groupCache.value = []
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Color = getRandomColor()
        }
        groupCache.value = response.data
      }, err =>{
        err
      })
    }

    return {
      groupCache,
      getRandomColor,
      emitGroupId,
      onDelete,
      onUpdate,
      isUpdate,
      itemsUpdate,
      isopenmodal,
      openModalAdd,
      isNeedRefresh,
      isModalClose,
    };
  },
});
