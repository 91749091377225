
import { defineComponent, onMounted, ref } from "vue";
import IstGroupComponent from "@/views/psy/ist/component/IstGroupComponent.vue";
import IstQuestionComponent from "@/views/psy/ist/component/IstQuestionComponent.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ist-adm",
  components: {
    IstGroupComponent,
    IstQuestionComponent
  },
  setup() {
    var IST_Group_Id = ref("")
    var IST_Group_Type = ref("")

    onMounted(() => {
      setCurrentPageBreadcrumbs("IST", []);
    });

    function itemIst(val) {
      IST_Group_Id.value = val.IST_Group_Id
      IST_Group_Type.value = val.IST_Group_Type
    }

    return {
      itemIst,
      IST_Group_Id,
      IST_Group_Type,
    };
  },
});
