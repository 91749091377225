
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

interface IST_Choice{
  ID: string,
  Description: string
}

interface IST_Correct_Answer{
  ID: string,
  Score: number
}

interface IstQuestion{
    IST_Question_Id : string;
		IST_Group_Id			: string;
		IST_Question			: string;
    IST_Order				: number;
		IST_Choice				: Array<IST_Choice>;
		IST_Correct_Answer		: Array<IST_Correct_Answer>;
}

export default defineComponent({
  name: "create-ist-question-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    istgroupid: String,
    istgrouptype: String,
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
  },
  setup(props, {emit}) {
    const submitButton = ref<HTMLElement | null>(null);
    const { istgroupid, isopenmodal } = toRefs(props);

    var istQuestion = ref<IstQuestion>({
      IST_Question_Id: "",
      IST_Group_Id: "",
      IST_Question: "",
      IST_Order	:  0,
      IST_Choice	:  [],
      IST_Correct_Answer : [],
    });

    var ist_choice = ref<IST_Choice>({
      ID: "",
      Description: "",
    });

    var ist_correct_answer = ref<IST_Correct_Answer>({
      ID: "",
      Score: 0,
    });

    let fcorrectanswer = ref(false)
    let dialogAddQuestion = ref(false)
    var avatarFile = ref<File | null>(null); // Variable to hold the avatar file
    var avatarImageUrl = ref('media/avatars/blank.png'); // Default image

    const handleFileUpload = (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
          var file = input.files[0];
          avatarFile.value = file; // Save the file

          // Create a URL for the selected file
          avatarImageUrl.value = URL.createObjectURL(avatarFile.value)
        }
    };

    const removeImage = () => {
      avatarFile.value = null
      avatarImageUrl.value = "media/avatars/blank.png";
    };

    const istValidator = Yup.object().shape({
      fno: Yup.string().required().label("No."),
      fquest: Yup.string().required().label("Pertanyaan"),
      fvidquest: Yup.string().required().label("Id Jawaban"),
      fvdescquest: Yup.string().required().label("Desc Jawaban"),
    });

    watch(isopenmodal, async (newVal, oldVal) => {
      istQuestion.value.IST_Question_Id = ""
      istQuestion.value.IST_Group_Id = ""
      istQuestion.value.IST_Question = ""
      istQuestion.value.IST_Order = 0
      istQuestion.value.IST_Correct_Answer =  []
      istQuestion.value.IST_Choice	=  []

      ist_correct_answer.value.ID = ""
      ist_correct_answer.value.Score = 0

      ist_choice.value.ID = ""
      ist_choice.value.Description = ""

      removeImage()

      if(props.isupdate){
        istQuestion.value.IST_Question_Id = props.itemsupdate.IST_Question_Id
        istQuestion.value.IST_Group_Id = props.itemsupdate.IST_Group_Id
        istQuestion.value.IST_Question = props.itemsupdate.IST_Question
        istQuestion.value.IST_Order = props.itemsupdate.IST_Order
        istQuestion.value.IST_Correct_Answer =  props.itemsupdate.IST_Correct_Answer
        istQuestion.value.IST_Choice	=  props.itemsupdate.IST_Choice

        ist_correct_answer.value.ID = ""
        ist_correct_answer.value.Score = 0

        ist_choice.value.ID = ""
        ist_choice.value.Description = ""

        if(props.istgrouptype == 'PSO'){
          if(istQuestion.value.IST_Question !=''
              && istQuestion.value.IST_Question !=undefined
              && istQuestion.value.IST_Question != null
          ){
            avatarImageUrl.value = `${ApiService.getUrl()}/IST/storage/${istQuestion.value.IST_Question}`
            avatarFile.value = await convertUrlToFile(avatarImageUrl.value, istQuestion.value.IST_Question)
          }
        }
      }

      if(newVal){
        dialogAddQuestion.value = true
      }
    });

    async function convertUrlToFile(imageUrl, fileName) {
        // Step 1: Fetch the image data
        const response = await fetch(imageUrl, { mode: 'no-cors' });
        const blob = await response.blob(); // Step 2: Create a Blob

        // Step 3: Create a File from the Blob
        var file = new File([blob], fileName, { type: blob.type });
        return file;
    }

    async function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)

      if(props.istgroupid == "" || props.istgroupid == null || props.istgroupid == undefined){
         toast("Save Gagal, Silahkan pilih group dahulu", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        return cancelModal()
      }

      saveObj['Trigger'] = 'C'
      if(props.isupdate){
        saveObj['Trigger'] = 'U'
      }
      saveObj['Route'] = 'IST_Questions'
      saveObj['IST_Group_Id'] = props.istgroupid

      var correctListResult =  ref([] as Array<any>)
      var correctGroupByScore = saveObj['IST_Correct_Answer'].groupBy(["Score"])
      if(props.istgrouptype != "SO" && props.istgrouptype != "PSO" ){
        for(let i=0; i<correctGroupByScore.length; i++){
          var obj = {}
          obj['Score'] = correctGroupByScore[i]['Score']
          obj['ID'] =  [] 
          for(let j=0; j<correctGroupByScore[i].items.length; j++){
              obj['ID'].push(correctGroupByScore[i]['items'][j]['ID'])
          }
          correctListResult.value.push(obj) 
        }
        saveObj['IST_Correct_Answer'] = correctListResult.value
      }

      saveObj['IST_Choice'] = JSON.stringify(saveObj['IST_Choice'])
      if(props.istgrouptype == "OW")
        saveObj['IST_Choice'] = JSON.stringify([])
      
      saveObj['IST_Correct_Answer'] = JSON.stringify(saveObj['IST_Correct_Answer'])

      if(props.istgrouptype == "PSO"){
         saveObj['IST_Question'] = null
         saveObj['IST_Question'] = avatarFile.value
         await Services.PostData(ApiService, "IST", saveObj, response=>{
          // responseCache = response.data
          toast("Save Berhasil!", 
          {
            autoClose: 1000,
            type: 'success',
            pauseOnFocusLoss: false,
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          emit("isneedrefresh", props.istgroupid);
          cancelModal()
        }, err =>{
          err
          toast("Save Gagal", 
          {
            autoClose: 1000,
            type: 'error',
            pauseOnFocusLoss: false,
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          cancelModal()
        })

      }else{   
        await Services.PostDataXWWW(ApiService, "IST", saveObj, response=>{
          // responseCache = response.data
          toast("Save Berhasil!", 
          {
            autoClose: 1000,
            type: 'success',
            pauseOnFocusLoss: false,
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          emit("isneedrefresh", props.istgroupid);
          cancelModal()
        }, err =>{
          err
          toast("Save Gagal", 
          {
            autoClose: 1000,
            type: 'error',
            pauseOnFocusLoss: false,
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
          cancelModal()
        })
      }
    };

    const saveIst = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(istQuestion)
      }
    };

    function addQuestionList(istQuestion, ist_choice, ist_correct_answer){
      if(ist_choice.ID && ist_choice.Description){
          ist_correct_answer.ID = ist_choice.ID
          istQuestion.IST_Choice.push({
            ID: ist_choice.ID,
            Description: ist_choice.Description
          })
          if(fcorrectanswer.value){
            istQuestion.IST_Correct_Answer.push({
              ID: ist_correct_answer.ID,
              Score: ist_correct_answer.Score
            })
          }
          ist_choice.ID = ""
          ist_choice.Description = ""
          ist_correct_answer.ID = ""
          ist_correct_answer.Score = 0
          fcorrectanswer.value = false
      }
    }

    function modalClose(){
      dialogAddQuestion.value = false
      emit("modalClose", true);
    }

    function removeQuestionList(istQuestion){
      istQuestion.IST_Choice = []
      istQuestion.IST_Correct_Answer = []
    }

    function cancelModal(){
      istQuestion.value.IST_Group_Id = ""
      istQuestion.value.IST_Question = ""
      istQuestion.value.IST_Order = 0
      
      istQuestion.value.IST_Choice = []
      istQuestion.value.IST_Correct_Answer = []

      ist_choice.value.ID = ""
      ist_choice.value.Description = ""

      ist_correct_answer.value.ID = ""
      ist_correct_answer.value.Score = 0

      removeImage()

      modalClose()
    }

    return {
      istQuestion,
      ist_choice,
      ist_correct_answer,
      istValidator,
      saveIst,
      submitButton,
      addQuestionList,
      removeQuestionList,
      fcorrectanswer,
      dialogAddQuestion,
      cancelModal,
      modalClose,
      removeImage,
      avatarFile,
      handleFileUpload,
      avatarImageUrl,
      convertUrlToFile,
    };
  },
});
