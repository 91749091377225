import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IstGroupComponent = _resolveComponent("IstGroupComponent")!
  const _component_IstQuestionComponent = _resolveComponent("IstQuestionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IstGroupComponent, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        onClickDraw: _ctx.itemIst
      }, null, 8, ["onClickDraw"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_IstQuestionComponent, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        "ist-group-id": _ctx.IST_Group_Id,
        "ist-group-type": _ctx.IST_Group_Type
      }, null, 8, ["ist-group-id", "ist-group-type"])
    ])
  ]))
}