import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder text-dark" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "card-body pt-5" }
const _hoisted_8 = { class: "col-2" }
const _hoisted_9 = { class: "symbol symbol-40px me-1" }
const _hoisted_10 = { class: "text-dark fw-bold" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "",
  "data-bs-original-title": "Edit"
}
const _hoisted_13 = { class: "svg-icon svg-icon-3" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "svg-icon svg-icon-3" }
const _hoisted_16 = { class: "col-10" }
const _hoisted_17 = { class: "d-flex flex-column" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "row mb-6" }
const _hoisted_20 = { class: "col-lg-8" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 1,
  class: "text-dark text-hover-primary fs-6 fw-bolder"
}
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-6" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "row mb-6" }
const _hoisted_27 = { class: "col-lg-8" }
const _hoisted_28 = { class: "text-muted fw-bold" }
const _hoisted_29 = ["src"]
const _hoisted_30 = {
  key: 1,
  class: "text-muted fw-bold"
}
const _hoisted_31 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CreateIstQuestionComponent = _resolveComponent("CreateIstQuestionComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.widgetClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, "IST Soal - " + _toDisplayString(_ctx.istGroupId), 1),
          _createElementVNode("span", _hoisted_4, "Total " + _toDisplayString(_ctx.questionCache.length) + " Soal", 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
            "data-kt-menu-trigger": "click",
            "data-kt-menu-placement": "bottom-end",
            "data-kt-menu-flip": "top-end",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalAdd(false, {})))
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionCache, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "row"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([{ 'mb-7': _ctx.questionCache.length - 1 !== index }, "d-flex align-items-center"])
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`bg-light-${item.Color}`, "symbol-label"])
                  }, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(item.IST_Order), 1)
                  ], 2),
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.onUpdate(item)),
                    class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                    "data-bs-toggle": "tooltip",
                    title: "",
                    "data-kt-customer-payment-method": "update",
                    "data-bs-original-title": "Update"
                  }, [
                    _createElementVNode("span", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ])
                  ], 8, _hoisted_11),
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.onDelete(item)),
                    class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                    "data-bs-toggle": "tooltip",
                    title: "",
                    "data-kt-customer-payment-method": "delete",
                    "data-bs-original-title": "Delete"
                  }, [
                    _createElementVNode("span", _hoisted_15, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_14)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", {
                    class: "fs-6 fw-bolder",
                    style: {"color":"red"}
                  }, "Soal :", -1)),
                  (_ctx.istGroupType=='PSO')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("img", {
                              src: item.avatarImageUrl || 'media/avatars/blank.png',
                              alt: "Image Preview"
                            }, null, 8, _hoisted_21)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(item.IST_Question), 1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[1] || (_cache[1] = _createElementVNode("p", {
                        class: "fs-6 fw-bolder",
                        style: {"color":"red"}
                      }, "Jawaban :", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Choice, (choiceItem, index2) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index2 }, [
                          (_ctx.istGroupType=='PSO')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                  _createElementVNode("div", _hoisted_27, [
                                    _createElementVNode("p", _hoisted_28, _toDisplayString(choiceItem.ID) + ".", 1),
                                    _createElementVNode("img", {
                                      src: choiceItem.Description || 'media/avatars/blank.png',
                                      alt: "Image Preview"
                                    }, null, 8, _hoisted_29)
                                  ])
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_30, _toDisplayString(choiceItem.ID) + "." + _toDisplayString(choiceItem.Description), 1))
                        ], 64))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[2] || (_cache[2] = _createElementVNode("p", {
                        class: "fs-6 fw-bolder",
                        style: {"color":"red"}
                      }, "Kunci Jawaban :", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Correct_Answer, (correctItem, index3) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index3,
                          class: "text-muted fw-bold"
                        }, _toDisplayString(correctItem.ID) + ". Score = " + _toDisplayString(correctItem.Score), 1))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ], 2)
          ]))
        }), 128))
      ])
    ], 2),
    _createVNode(_component_CreateIstQuestionComponent, {
      isopenmodal: _ctx.isopenmodal,
      onModalClose: _ctx.isModalClose,
      istgroupid: _ctx.istGroupId,
      istgrouptype: _ctx.istGroupType,
      isupdate: _ctx.isUpdate,
      itemsupdate: _ctx.itemsUpdate,
      onIsneedrefresh: _ctx.isNeedRefresh
    }, null, 8, ["isopenmodal", "onModalClose", "istgroupid", "istgrouptype", "isupdate", "itemsupdate", "onIsneedrefresh"])
  ], 64))
}