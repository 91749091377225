
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

interface Ist {  
  IST_Group_Name: string;
  IST_Group_Id: string;
  IST_Questions_Number	:  string;
  IST_Group_Description	:  string;
  IST_Duration : number;
  IST_Group_Type: string;
}

export default defineComponent({
  name: "create-ist-group-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
  },
  setup(props, {emit}) {
    const { isopenmodal, itemsupdate } = toRefs(props);

    const submitButton = ref<HTMLElement | null>(null);
    var ist = ref<Ist>({
      IST_Group_Name: "",
      IST_Group_Id: "",
      IST_Questions_Number	:  "",
      IST_Group_Description	:  "",
      IST_Duration : 0,
      IST_Group_Type: "SO",
    });
    let dialogAddGroup = ref(false)

    watch(isopenmodal, (newVal, oldVal) => {
      ist.value.IST_Group_Name = ""
      ist.value.IST_Group_Id = ""
      ist.value.IST_Questions_Number =  ""
      ist.value.IST_Group_Description	=  ""
      ist.value.IST_Duration = 0
      ist.value.IST_Group_Type= "SO"

      if(props.isupdate){
        ist.value.IST_Group_Name = props.itemsupdate.IST_Group_Name
        ist.value.IST_Group_Id= props.itemsupdate.IST_Group_Id
        ist.value.IST_Questions_Number	=  props.itemsupdate.IST_Questions_Number
        ist.value.IST_Group_Description	=  props.itemsupdate.IST_Group_Description
        ist.value.IST_Duration = props.itemsupdate.IST_Duration
        ist.value.IST_Group_Type= props.itemsupdate.IST_Group_Type
      }

      if(newVal){
        dialogAddGroup.value = true
      }
    });

    function modalClose(){
      dialogAddGroup.value = false
      emit("modalClose", true);
    }

    const istValidator = Yup.object().shape({
      fno: Yup.string().required().label("No."),
      fname: Yup.string().required().label("Nama"),
      fnumber: Yup.string().required().label("No. Soal"),
      fdesc: Yup.string().required().label("Deskripsi"),
      fdurasi: Yup.string().required().label("Durasi"),
      ftipe: Yup.string().required().label("Durasi"),
    });

    async function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)

      if(saveObj['IST_Duration'] == "" || saveObj['IST_Duration'] == null || saveObj['IST_Duration'] == undefined)
         saveObj['IST_Duration'] = 0

      saveObj['Trigger'] = 'C'
      if(props.isupdate){
        saveObj['Trigger'] = 'U'
      }
      saveObj['Route'] = 'IST_Groups'

      var responseCache = []
      await Services.PostDataXWWW(ApiService, "IST", saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    };

    const saveIst = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(ist)
      }
    };

    function cancelModal(){
      ist.value.IST_Group_Name = ""
      ist.value.IST_Group_Name = ""
      ist.value.IST_Group_Id = ""
      ist.value.IST_Questions_Number	=  ""
      ist.value.IST_Group_Description	=  ""
      ist.value.IST_Duration = 0
      ist.value.IST_Group_Type= "SO"

      modalClose()
    }

    return {
      // data,
      ist,
      istValidator,
      saveIst,
      submitButton,
      dialogAddGroup,
      cancelModal,
      modalClose,
    };
  },
});
