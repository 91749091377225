
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "vue";
import CreateIstQuestionComponent from "@/views/psy/ist/component/CreateIstQuestionComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";

export default defineComponent({
  name: "ist-question-component",
  components: {
    CreateIstQuestionComponent,
  },
  props: {
    widgetClasses: String,
    istGroupId: String,
    istGroupType: String,
  },
  setup(props, {emit}) {
    const { istGroupId, istGroupType } = toRefs(props);
    var questionCache = ref([] as Array<any>)
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})

    watch(istGroupId, (newVal, oldVal) => {
      getIstQuestion(newVal)
    });

    onBeforeMount(() => {
      // console.log("sin")
      // getIstQuestion()
    });

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    function onDelete(val){
      deleteIstQuestion(val.IST_Group_Id ,val.IST_Question_Id)
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function isNeedRefresh(val) {
      getIstQuestion(val)
    }

    async function getIstQuestion(val){
      const mydatat = {
        Trigger					: "R",
        Route					: "IST_Questions",
        IST_Group_Id			: val,
      }

      questionCache.value = []
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Color = getRandomColor()
        }
        questionCache.value = response.data.map(item => ({ 
          ...item, 
          avatarImageUrl: `${ApiService.getUrl()}/IST/storage/${item.IST_Question}`, 
          IST_Choice: item.IST_Choice.map(choice => ({
            ...choice,
            avatarImageUrl: choice.Description, 
            // avatarImageUrl: `${ApiService.getUrl()}/IST/storage/${choice.Description}`, 
            // Add any modifications you need for each choice here
          })),
        }));
        // console.log(response.data)
      }, err =>{
        err
      })
    }

  async function deleteIstQuestion(IST_Group_Id, IST_Question_Id){
      const mydatat = {
        Trigger					: "D",
        Route					: "IST_Questions",
        IST_Question_Id			: IST_Question_Id
      }

      questionCache.value = []
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response=>{
        getIstQuestion(IST_Group_Id)
      }, err =>{
        err
      })
    }

    function openModalAdd(isupdate, itemsupdate){
      if(props.istGroupId == undefined 
          || props.istGroupId == null 
          || props.istGroupId == ""){
            isopenmodal.value = false
      }else{
        isUpdate.value = isupdate
        itemsUpdate.value = itemsupdate
        isopenmodal.value = true
      }
    }

    return {
      questionCache,
      getRandomColor,
      openModalAdd,
      isopenmodal,
      onDelete,
      isUpdate,
      itemsUpdate,
      isNeedRefresh,
      isModalClose,
      onUpdate,
    };
  },
});
