import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"overflow-y":"auto","max-height":"40vh","margin-bottom":"20px"} }
const _hoisted_2 = { class: "mb-10" }
const _hoisted_3 = { class: "fv-plugins-message-container" }
const _hoisted_4 = { class: "fv-help-block" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "fv-plugins-message-container" }
const _hoisted_7 = { class: "fv-help-block" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "fv-plugins-message-container" }
const _hoisted_10 = { class: "fv-help-block" }
const _hoisted_11 = { class: "mb-10" }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = { class: "mb-10" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "mb-10" }
const _hoisted_18 = { class: "fv-plugins-message-container" }
const _hoisted_19 = { class: "fv-help-block" }
const _hoisted_20 = { class: "d-flex justify-content-end" }
const _hoisted_21 = {
  type: "submit",
  id: "kt_ist_submit",
  ref: "submitButton",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "before-close": _ctx.modalClose,
    modelValue: _ctx.dialogAddGroup,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dialogAddGroup) = $event)),
    title: "Tambah / Edit IST Grup",
    width: "50%",
    "align-center": "true",
    style: { maxHeight: '60vh' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Form, {
          id: "kt_ist_form",
          class: "form",
          novalidate: "novalidate",
          onSubmit: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveIst())),
          "validation-schema": _ctx.istValidator
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "No. :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fno",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "No. ",
                  modelValue: _ctx.ist.IST_Group_Name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ist.IST_Group_Name) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ErrorMessage, { name: "fno" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Nama :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fname",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Nama",
                  modelValue: _ctx.ist.IST_Group_Id,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ist.IST_Group_Id) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_ErrorMessage, { name: "fname" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "No. Soal :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fnumber",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "No. Soal",
                  modelValue: _ctx.ist.IST_Questions_Number,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ist.IST_Questions_Number) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_ErrorMessage, { name: "fnumber" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Deskripsi :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fdesc",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Deskripsi",
                  modelValue: _ctx.ist.IST_Group_Description,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ist.IST_Group_Description) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_ErrorMessage, { name: "fdesc" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Durasi :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  name: "fdurasi",
                  class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                  placeholder: "Deskripsi",
                  modelValue: _ctx.ist.IST_Duration,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ist.IST_Duration) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ErrorMessage, { name: "fdurasi" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-bold fs-6" }, "Tipe :", -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_Field, {
                  as: "select",
                  name: "ftipe",
                  class: "form-select form-select-solid form-select-lg fw-bold",
                  modelValue: _ctx.ist.IST_Group_Type,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ist.IST_Group_Type) = $event))
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createElementVNode("option", { value: "SO" }, "SO-Select One", -1),
                    _createElementVNode("option", { value: "OW" }, "OW-One Word", -1),
                    _createElementVNode("option", { value: "MA" }, "MA-Multiple Answers", -1),
                    _createElementVNode("option", { value: "PSO" }, "PSO-Picture Select One", -1)
                  ])),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_ErrorMessage, { name: "ftipe" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("button", {
                type: "reset",
                class: "btn btn-sm btn-white btn-active-light-primary me-2",
                "data-kt-menu-dismiss": "true",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancelModal()))
              }, " Batal "),
              _createElementVNode("button", _hoisted_21, _cache[16] || (_cache[16] = [
                _createElementVNode("span", { class: "indicator-label" }, " Simpan ", -1),
                _createElementVNode("span", { class: "indicator-progress" }, [
                  _createTextVNode(" Please wait... "),
                  _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                ], -1)
              ]), 512)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ]),
    _: 1
  }, 8, ["before-close", "modelValue"]))
}